/* eslint-disable react/no-unused-prop-types */

/**
 * What we do in this Component:
 */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";
import PriceInput from "@cx/ui/PriceInput";
import NumericInput from "@cx/ui/NumericInput";
import Tooltip from "@cx/ui/Tooltip";
import Badge from "@cx/ui/Badge";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { priceValueFormatter } from "../utils/format.util";
import { serviceTypes } from "../utils/edit-service.constants";
import "./LaborComponent.scss";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { useLaborCalculation } from "../hooks";
import LaborPartsFields from "../components/csr/labor-parts-fields.component";
import { buildPayTypeOption } from "../../ServiceSearchModule/utils/service.util";
import {
  buildPayTypeCostAllocationOptions,
  buildPayTypeSubTypeOptions,
  buildServiceContractOptions,
  buildServiceTypeOption,
  generateCostAllocationOptions,
  isDMSPInternalAccAvailable
} from "../utils/labor-calculation.util";
import ModalDialog from "@cx/ui/ModalDialog";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import { appTypes } from "../../constants/app.constants";
import { AppContext } from "../../state/app-context";

const LaborComponent = props => {
  const {
    state: {
      appType,
      username,
      serviceType,
      debugMode,
      service: stateService,
      payTypes,
      payTypeSubTypes,
      costAllocationTypes,
      serviceTypes: serviceTypesList,
      vendorList: serviceContractList,
      currentEditingService
    },
    BODY_SHOP,
    defaultserviceCodeID,
    localLaborRate,
    defaultpayCodeID,
    disablePaytype,
    localLaborPrice,
    originalLaborPrice,
    localOpCode,
    laborHours,
    localCostAllocationSubTypeId,
    newLocalCostAllocationName,
    localSubTypeId,
    serviceTypeTemp,
    isDMSPlusDealer,
    localVendorName,
    onBlurOpCode,
    onBlurLaborTime,
    onBlurLaborPrice,
    onBlurLaborRate,
    handlePayTypeChange,
    handleOpCodeChange,
    handleLaborPriceChange,
    handleLaborTimeChange,
    handlePayTypeCostAllocationChange,
    handleNewPayTypeCostAllocationChange,
    handlePayTypeSubTypeChange,
    handleLaborRateChange,
    handleServiceTypeChange,
    handleServiceContractChange,
    payTypeConfirmationModal,
    showConfirmationModalForPayType,
    confirmPayTypeChange,
    isServiceContractPayType,
    isInsuranceSelected,
    localContractNumber,
    handleContractNumberChange,
    isAlwaysShowLaborRateEnabled
  } = useLaborCalculation(props);

  const isCSR = appType === appTypes.CSR;

  const {
    serviceId,
    laborTaxCode,
    // calculatedLaborPrice,
    totalPriceOverridden,
    payTypeCode
  } = stateService;

  const appContext = useContext(AppContext);
  const { userPermissions } = appContext;
  const newCostAllocationAccounts =
    appContext?.dealerProperties?.DMSP_INTERNAL_ACCOUNTS;

  const isDMSPInternalAccountsAvailable = isDMSPInternalAccAvailable(
    newCostAllocationAccounts
  );

  let costAllocationOptions = [];
  if (isDMSPInternalAccountsAvailable) {
    costAllocationOptions = generateCostAllocationOptions(
      newCostAllocationAccounts
    );
  }

  const isMenuPackage = serviceType === serviceTypes.MENU;
  const isInternalPay = payTypeCode === "I";
  const showServiceTypeField = isDMSPlusDealer && !isMenuPackage;
  const showOldDepartmentAndCostAllocationFields =
    isInternalPay &&
    userPermissions.canViewPrices &&
    !isDMSPInternalAccountsAvailable;
  const showNewCostAllocationField =
    isInternalPay &&
    userPermissions.canViewPrices &&
    isDMSPInternalAccountsAvailable;
  const showServiceContractField =
    isCSR &&
    isServiceContractPayType &&
    !isMenuPackage &&
    userPermissions.canViewPrices;
  const showLaborRateField =
    isAlwaysShowLaborRateEnabled ||
    (isDMSPlusDealer &&
      serviceTypeTemp === BODY_SHOP &&
      serviceTypesList.length > 0 &&
      userPermissions.canViewPrices) ||
    showServiceContractField;

  return (
    <>
      <div className="labor-wrapper">
        <div className="edit-quote-labor no-border" key={"labor-" + serviceId}>
          <div className="labor-title">
            <span>Labor</span>
            {!debugMode
              ? ""
              : stateService.laborPriceOverridden && (
                  <div className="edit-override-label-container">
                    <Badge htmlId="overrideBadge" color="red">
                      {priceValueFormatter(
                        stateService.totalLaborPriceOverride
                      )}
                    </Badge>
                  </div>
                )}
          </div>
          {userPermissions.canViewPrices ? (
            <div className="price-with-tooltip-container">
              {/* serviceType check for rendering only on menu services will be removed as soon as other services require tooltip */}
              {serviceType === serviceTypes.MENU &&
              parseFloat(localLaborPrice || 0) !==
                parseFloat(originalLaborPrice || 0) ? (
                <Tooltip
                  htmlId="laborPriceEdited"
                  tooltipContent={`Labor price changed from ${priceValueFormatter(
                    originalLaborPrice
                  )} to ${priceValueFormatter(localLaborPrice)} by ${username}`}
                  position="left"
                >
                  <IconInfoOutline htmlId="iconFee" />
                </Tooltip>
              ) : (
                ""
              )}
              <div className="labor-price">
                {priceValueFormatter(localLaborPrice)}
              </div>
            </div>
          ) : null}
        </div>
        <div className="labor-form">
          {/* The "Pay type" field is always visible for everyone */}
          <SelectInput
            htmlId="payTypeSelect"
            name="payType"
            label="Pay type"
            maxHeight={150}
            options={[...buildPayTypeOption(payTypes, appType)]}
            value={defaultpayCodeID !== "-1" ? defaultpayCodeID : ""}
            disabled={
              disablePaytype || !userPermissions.canUpdateServiceAttributes
            }
            displayDeselectOption={false}
            onChange={cxEvent =>
              currentEditingService
                ? showConfirmationModalForPayType(cxEvent, true)
                : handlePayTypeChange(cxEvent)
            }
            className="input-field pay-type"
            required={userPermissions.canUpdateServiceAttributes}
          />
          {/* TODO: remove this condition when serviceType is implemented for menu */}
          {showServiceTypeField ? (
            <SelectInput
              htmlId="serviceTypeSelect"
              name="serviceType"
              label="Service type"
              maxHeight={150}
              options={[...buildServiceTypeOption(serviceTypesList)]}
              value={defaultserviceCodeID !== "-1" ? defaultserviceCodeID : ""}
              displayDeselectOption={false}
              onChange={handleServiceTypeChange}
              className="input-field service-type"
              required={userPermissions.canUpdateServiceAttributes}
              disabled={!userPermissions.canUpdateServiceAttributes}
            />
          ) : null}
          {showOldDepartmentAndCostAllocationFields ? (
            <>
              <SelectInput
                htmlId="payTypeSubTypeSelect"
                name="payTypeSubTypeSelect"
                label="Department"
                options={[...buildPayTypeSubTypeOptions(payTypeSubTypes)]}
                value={String(localSubTypeId ?? "")}
                displayDeselectOption={false}
                placeholder="Select"
                onChange={handlePayTypeSubTypeChange}
                className="input-field pay-type-sub-type"
                required={userPermissions.canUpdateServiceAttributes}
                disabled={!userPermissions.canUpdateServiceAttributes}
              />
              <SearchableSelect
                htmlId="costAllocationSelectRequired"
                label="Cost allocation"
                name="costAllocationSelectRequired"
                className="input-field pay-type-cost-allocation"
                onChange={handlePayTypeCostAllocationChange}
                options={[
                  ...buildPayTypeCostAllocationOptions(costAllocationTypes)
                ]}
                value={String(localCostAllocationSubTypeId ?? "")}
                placeholder="Select"
                enableMultiSelect={false}
                required={userPermissions.canUpdateServiceAttributes}
                disabled={!userPermissions.canUpdateServiceAttributes}
              />
            </>
          ) : null}
          {showNewCostAllocationField ? (
            <SearchableSelect
              htmlId="newCostAllocationSelectRequired"
              label="Cost allocation"
              name="newCostAllocationSelectRequired"
              className="input-field pay-type-cost-allocation"
              onChange={handleNewPayTypeCostAllocationChange}
              options={costAllocationOptions}
              value={newLocalCostAllocationName ?? ""}
              placeholder="Select"
              enableMultiSelect={false}
              required={userPermissions.canUpdateServiceAttributes}
              disabled={!userPermissions.canUpdateServiceAttributes}
            />
          ) : null}
          {showServiceContractField ? (
            <SearchableSelect
              htmlId="serviceContractSelectRequired"
              label="Service contract / Insurance"
              name="serviceContractSelectRequired"
              className="input-field service-contract"
              onChange={handleServiceContractChange}
              options={[...buildServiceContractOptions(serviceContractList)]}
              value={String(localVendorName ?? "")}
              placeholder="Select"
              enableMultiSelect={false}
              required={isServiceContractPayType}
            />
          ) : null}
          {showServiceContractField && isInsuranceSelected ? (
            <TextInput
              htmlId="editServiceContractPolicyNumber"
              label="Policy Number"
              name="edit-service-policy-number"
              onChange={handleContractNumberChange}
              value={localContractNumber ?? ""}
              maxLength={256}
              className="input-field op-code"
              autoComplete="off"
            />
          ) : null}
          {/* The "Op code" field is always visible for everyone */}
          <TextInput
            htmlId="editServiceOpCode"
            label="Op code"
            name="edit-service-op-code"
            onChange={handleOpCodeChange}
            onBlur={onBlurOpCode}
            value={localOpCode}
            maxLength={256}
            className="input-field op-code"
            autoComplete="off"
            disabled={!userPermissions.canUpdateServiceAttributes}
          />
          {appType === "CSR" && userPermissions.canViewPrices ? (
            <div className="tax-field">
              <LaborPartsFields laborTaxCode={laborTaxCode} />
            </div>
          ) : null}
          {userPermissions.canViewPrices ? (
            <NumericInput
              htmlId="editServiceHours"
              label="Hours"
              name="edit-service-hours"
              maxHeight={150}
              onChange={handleLaborTimeChange}
              className="input-field service-hours"
              maxLength={4}
              maxValue={999.99}
              placeholder="0"
              minValue={0}
              value={laborHours}
              allowDecimal={true}
              decimalMaxLength={2}
              decimalMinLength={1}
              onBlur={onBlurLaborTime}
              autoComplete="off"
            />
          ) : null}
          {showLaborRateField ? (
            <PriceInput
              htmlId="editServiceLaborRate"
              inputPrefix="$"
              label="Labor rate"
              name="edit-service labor-rate"
              onChange={handleLaborRateChange}
              className="input-field labor-rate"
              maxValue={99999.99}
              value={String(localLaborRate)}
              onBlur={onBlurLaborRate}
              autoComplete="off"
              required={showServiceContractField}
            />
          ) : null}
          {userPermissions.canViewPrices ? (
            <PriceInput
              htmlId="editServiceTotalLabor"
              inputPrefix="$"
              label="Total labor"
              name="edit-service-total-labor"
              onChange={handleLaborPriceChange}
              className="input-field total-labor"
              maxValue={99999.99}
              value={String(localLaborPrice)}
              onBlur={onBlurLaborPrice}
              autoComplete="off"
              disabled={
                totalPriceOverridden
                  ? true
                  : isAlwaysShowLaborRateEnabled
                  ? false
                  : serviceTypeTemp === BODY_SHOP || showServiceContractField
              }
              allowNegative
            />
          ) : null}
          {totalPriceOverridden ? (
            <div className="labor-price-tooltip">
              <Tooltip
                htmlId="laborPriceTooltipHtmlId"
                tooltipContent="Labor price can't be changed because the total price is fixed"
                className="list-tooltip"
              >
                <IconInfoOutline className="info" />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>

      <ModalDialog
        htmlId="PriceAdjustmentModalDialog"
        backdrop="static"
        className="sample-modal-dialog quarter-width"
        show={payTypeConfirmationModal}
        header={<h4 className="modal-title">Are you sure ?</h4>}
        footer={
          <div>
            <Button
              htmlId="cancelbtnModal"
              buttonStyle="secondary"
              onClick={() => showConfirmationModalForPayType(null, false)}
            >
              Cancel
            </Button>

            <Button
              htmlId="okbtnModal"
              onClick={confirmPayTypeChange}
              buttonStyle="danger"
              tabIndex="0"
            >
              Proceed
            </Button>
          </div>
        }
        onHide={() => showConfirmationModalForPayType(null, false)}
      >
        <div className="sq-flex-container">
          <Alert htmlId="dangerAlert" type="danger" className="margin-top-12">
            Changing the pay type may result in recalculation and removal of
            discounts and fees.
          </Alert>
        </div>
      </ModalDialog>
    </>
  );
};

LaborComponent.defaultProps = {
  service: null,
  payTypes: null,
  axiosInstance: null,
  defaultPayTypeCode: "",
  defaultServiceTypeCode: "",
  dealerCode: null
};

LaborComponent.propTypes = {
  onChangePaytype: PropTypes.func,
  onChangeServiceType: PropTypes.func,
  onChangeServiceContract: PropTypes.func,
  service: PropTypes.object,
  defaultPayTypeCode: PropTypes.string,
  defaultServiceTypeCode: PropTypes.string,
  payTypes: PropTypes.array,
  axiosInstance: PropTypes.object,
  dealerCode: PropTypes.string
};

export default LaborComponent;
