import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import React, { useState } from "react";
import PaymentNotes from "./payment-notes.component";

const ChargeAccountPaymentComponent = ({
  payerConfig,
  paymentLine,
  chargeAccountDetails,
  removePaymentLine,
  updateSelectedPaymentLines
}) => {
  const [currentPaymentLine, setCurrentPaymentLine] = useState(paymentLine);

  const onChargeAccountPriceChange = cxEvent => {
    const value = cxEvent.target.value;
    if (cxEvent.target.name === "allowCharge") {
      setCurrentPaymentLine({
        ...paymentLine,
        allowCharge: cxEvent.target.checked
      });
      updateSelectedPaymentLines({
        ...paymentLine,
        allowCharge: cxEvent.target.checked
      });
    } else if (cxEvent.target.name === "paymentAmount") {
      setCurrentPaymentLine({
        ...paymentLine,
        paymentAmount: value
      });
      updateSelectedPaymentLines({
        ...paymentLine,
        paymentAmount: value
      });
    } else if (cxEvent.target.name === "notes") {
      setCurrentPaymentLine({
        ...paymentLine,
        notes: value
      });
      updateSelectedPaymentLines({
        ...paymentLine,
        notes: value
      });
    }
  };

  const getChargeAccountValidationMessage = () => {
    const message =
      chargeAccountDetails?.isInGoodStanding === true
        ? chargeAccountDetails?.availableCredit?.amount >
          payerConfig?.payer.finalPrice
          ? null
          : "Low credit limit available"
        : "Account on hold";

    return message;
  };

  return (
    <div className="charge-account-payment-container">
      <span className="payment-method-text">Charge account</span>
      <div className="charge-account-payment-inputs">
        <NumericInput
          htmlId="priceInputDefault"
          className="charge-available-credit"
          label="Available credit:"
          name="name"
          disabled={true}
          allowNegative={true}
          onChange={() => {}}
          value={chargeAccountDetails?.availableCredit?.amount || ""}
          autoComplete="off"
        />
        <PriceInput
          htmlId="priceInputDefault"
          className="charge-amount"
          label="Amount"
          name="paymentAmount"
          onChange={onChargeAccountPriceChange}
          value={
            currentPaymentLine?.paymentAmount
              ? String(currentPaymentLine?.paymentAmount)
              : ""
          }
          required
          minValue={0.01}
          autoComplete="off"
        />
        <Button
          aria-label="charge account delete button"
          className="payment-line-button-delete"
          htmlId="btnIcon"
          buttonStyle="secondary"
          icon={<IconDelete htmlId="buttonIcon-iconAdd" />}
          onClick={() => {
            removePaymentLine(currentPaymentLine);
          }}
        />
      </div>
      <div className="charge-account-payment-validations">
        <span className="charge-accout-payment-validation-message">
          {getChargeAccountValidationMessage()}
        </span>
        {!(
          chargeAccountDetails?.isInGoodStanding === true &&
          chargeAccountDetails?.availableCredit?.amount >
            payerConfig?.payer.finalPrice
        ) ? (
          <div className="charge-account-payment-allow-charge">
            <input
              className="allow-charge-check-box"
              type="checkbox"
              checked={currentPaymentLine?.allowCharge}
              value={currentPaymentLine?.allowCharge}
              onChange={onChargeAccountPriceChange}
              id="allowCharge"
              name="allowCharge"
            />
            <label htmlFor="allowCharge" className="allow-charge-label">
              Allow charge
            </label>
          </div>
        ) : null}
      </div>
      <PaymentNotes
        onPaymentNoteChange={onChargeAccountPriceChange}
        currentPaymentLine={currentPaymentLine}
      />
    </div>
  );
};

export default ChargeAccountPaymentComponent;
