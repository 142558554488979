import isEmpty from "lodash/isEmpty";
import { convertHoursToMinutes, trimPrice } from "./quote-util";
import { calculatePriceAdjustmentTotal } from "../../repair-order/utils/price-adjustment-utils";
import {
  OperationSources,
  QuoteServiceTypes
} from "../constants/page-wrapper.constants";
import cloneDeep from "lodash/cloneDeep";
import isNumber from "lodash/isNumber";
import get from "lodash/get";
import { payTypeCodes } from "../../../constants/quote-status.constants";
import { appTypes } from "../../../constants/app.constants";

// This method returns quoteService with modified edit service values for non-global ops
const updateQuoteService = (currentEditingService, editedService, appType) => {
  console.log("updateQuoteService", currentEditingService, editedService);
  // cloneDeep will copy quoteId, quoteServiceId, extServiceId, etc.
  const quoteService = cloneDeep(currentEditingService);
  const { operationSource, quoteServiceType } = currentEditingService;
  let extLaborId = !isEmpty(currentEditingService.labor)
    ? currentEditingService?.labor?.extLaborId || null
    : null;

  // For dealer pub, diagnosis case, extLaborId will be same as extServiceId/laborAppId
  if (operationSource === OperationSources.DEALERCATALOG) {
    extLaborId = currentEditingService?.extServiceId || null;
  } else if (operationSource === OperationSources.GLOBALCATALOG) {
    // For globalops, extLaborId should be read from laborAppId (this value update in edit service context)
    extLaborId = currentEditingService?.labor?.extLaborId || null;
  } else if (
    appType === appTypes.SQ &&
    (quoteServiceType === OperationSources.RECALL ||
      quoteServiceType === OperationSources.DECLINED)
  ) {
    // For declined, recall, menus - always extLaborId will be null
    extLaborId = null;
    // @regression fix - set operationSource as null for DECLINED, RECALL, MENU services as per requirement to support quote API
    quoteService.operationSource = null;
    quoteService.serviceKind = null;
  }
  // @note: once serviceDescription saved under quoteService; read same field from currentEditingService
  const serviceDescription = get(
    currentEditingService,
    "serviceDescription",
    ""
  );
  quoteService.menuServices = null;
  quoteService.lastModDateTime = null;
  quoteService.dmsOpcode = get(editedService, "opCode", null);
  quoteService.subTypeId = get(editedService, "subTypeId", null);
  quoteService.allocationSubTypeId = get(
    editedService,
    "allocationSubTypeId",
    null
  );
  quoteService.internalAccount = get(editedService, "internalAccount", null);

  // @note: labor{} quoteServiceLaborId copied from saved quoteService
  // For Dealer pub, global ops case - labor.description saved by reading from catalogAPI "laborApp[index].displayName" - in add quote flow
  // For Menu, Recall, Declined cases - labor.description saved by reading API service.description - in add quote flow
  // Expected labor : {defaultLaborRate,extLaborId, description,laborPrice, laborTime}
  // Edit Quote flow: read & send same quoteService.labor.description in payload as below
  quoteService.labor = {
    ...quoteService.labor,
    laborPrice: editedService?.calculatedLaborPrice || 0,
    extLaborId,
    lastModTime: null,
    laborTime: convertHoursToMinutes(get(editedService, "labor.time", 0))
  };
  // TODO- parts case- quoteServicePartId not copied, why?
  if (operationSource === OperationSources.GLOBALCATALOG) {
    quoteService.parts = get(editedService, "filteredParts", []);
  } else {
    quoteService.parts = get(editedService, "parts", []);
  }
  quoteService.serviceDescription = serviceDescription || null;
  quoteService.asrNotes = editedService?.asrNotes || null;
  quoteService.dealershipNotes = editedService?.dealershipNotes || null;
  quoteService.payTypeGroup = editedService?.payTypeGroup || "";
  quoteService.payTypeCode = editedService?.payTypeCode || "";
  quoteService.payTypeDescription = editedService?.payTypeDescription || "";
  quoteService.serviceTypeCode = editedService?.serviceTypeCode || "";
  quoteService.totalPriceOverride = editedService?.totalPriceOverride || null;
  quoteService.totalLaborPriceOverride =
    editedService?.totalLaborPriceOverride ?? null;
  quoteService.totalPartsPriceOverride =
    editedService?.totalPartsPriceOverride || null;

  // IMPORTANT NOTE:--------------------------------------------------------------------------
  // Below calculation must be in this order since the getter depends on previous calculation
  // -----------------------------------------------------------------------------------------
  quoteService.calculatedTotalPartsPrice = trimPrice(
    getCalculatedTotalPartsPrice(quoteService.parts)
  );
  quoteService.calculatedServicePrice = trimPrice(
    quoteService.calculatedTotalPartsPrice + editedService.calculatedLaborPrice
  );
  // partsPrice will have original price (API returned)
  // @change - use finalPartsPrice updated from  price utils - either override or calculated price
  quoteService.finalPartsPrice = trimPrice(getFinalPartsPrice(quoteService));
  quoteService.finalLaborPrice = trimPrice(getFinalLaborPrice(quoteService));
  quoteService.servicePrice = trimPrice(getServicePrice(quoteService));

  //  For CSR specific laborTaxCode
  quoteService.laborTaxCode = editedService?.laborTaxCode || null;

  //  For CSR specific Cause
  quoteService.cause = editedService?.cause || null;

  //  For CSR specific correction
  quoteService.correction = editedService?.correction || null;

  //  For CSR specific complaint
  quoteService.complaint = editedService?.complaint || null;

  //  For CSR specific Service Line Complete
  quoteService.completedTime = editedService?.completedTime || null;

  //  For CSR specific Ready For Invoicing
  quoteService.paymentStatus = editedService?.paymentStatus || "notReady";
  //  For CSR specific Ready For Invoicing
  quoteService.technicians = editedService?.technicians || [];
  quoteService.catalogFees = editedService?.catalogFees || [];
  quoteService.catalogDiscounts = editedService?.catalogDiscounts || [];
  if (editedService?.payTypeCode === payTypeCodes.SERVICE_CONTRACT) {
    quoteService.serviceContract = editedService?.serviceContract;
  }

  // for CSR specific
  if (appType === "CSR") {
    // and PayType = Warranty
    if (editedService?.payTypeCode === "W") {
      quoteService.warranty = editedService?.warranty || null;
    }
    // TODO: lineServicePrice calculation needs to be moved to backend
    const calculatedLaborPrice =
      quoteService.totalLaborPriceOverride ?? quoteService.finalLaborPrice ?? 0;
    const calculatedServicePrice =
      calculatedLaborPrice +
      (quoteService.totalPartsPriceOverride ??
        quoteService.finalPartsPrice ??
        0) +
      trimPrice(calculatePriceAdjustmentTotal(quoteService?.sublets));

    quoteService.lineServicePrice = Math.max(0, calculatedServicePrice);
  }

  delete quoteService.defaultPayTypeCode;

  console.log("updateQuoteService output", quoteService);
  return quoteService;
};

const getServicePrice = service => {
  let servicePrice = 0;

  if (isNumber(service.totalPriceOverride)) {
    servicePrice = service.totalPriceOverride;
  }

  // If the service exists and its quoteServiceType is not set to RECALL,
  // calculate the total service price by summing up the final labor price and final parts price.
  if (service?.quoteServiceType !== QuoteServiceTypes.RECALL) {
    servicePrice = service.finalLaborPrice + service.finalPartsPrice;
  }

  return servicePrice;
};

const getCalculatedTotalPartsPrice = parts => {
  const hasParts = !isEmpty(parts) && Array.isArray(parts);
  const partObj = parts || [];
  const totalPartsPrice = hasParts
    ? partObj
        .map(part => (!part.unitPrice ? 0 : part.unitPrice * part.quantity))
        .reduce((acc, part) => acc + part, 0)
    : 0;
  return totalPartsPrice;
};

const getFinalPartsPrice = service => {
  if (isNumber(service.totalPriceOverride)) {
    return service.calculatedTotalPartsPrice;
  } else if (isNumber(service.totalPartsPriceOverride)) {
    return service.totalPartsPriceOverride;
  }
  return service.calculatedTotalPartsPrice;
};

const getFinalLaborPrice = service => {
  if (isNumber(service.totalPriceOverride)) {
    return service.totalPriceOverride - service.finalPartsPrice;
  } else if (isNumber(service.totalLaborPriceOverride)) {
    return service.totalLaborPriceOverride;
  }
  return service.labor.laborPrice;
};

export default {
  updateQuoteService,
  getServicePrice,
  getCalculatedTotalPartsPrice,
  getFinalPartsPrice,
  getFinalLaborPrice
};
