import React, { useEffect, useState, useRef } from "react";
import NumericInput from "@cx/ui/NumericInput";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";
import Button from "@cx/ui/Button";
import StatusBox from "./reusable/statusbox.component";
import isEmpty from "lodash/isEmpty";
import * as formvalidator from "../utils/helper.util";
import { isDifferentValue } from "../utils/helper.util";
import { mockPart } from "../constants/parts.constants";
import { usePartsLookupContext } from "../state/parts-lookup.context";

const AddPartComponent = props => {
  useEffect(() => {}, []);
  const { state } = usePartsLookupContext();
  // This field ref used to clear content in field
  const partNumRef = useRef(null);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState({
    quantity: "",
    partNumber: "",
    make: ""
  });
  const [loadErrors, setLoadErrors] = useState({
    quantity: "",
    partNumber: "",
    make: ""
  });
  const [msg, setMsg] = useState("");
  const [dirtyField, setDirtyField] = useState("");
  const [makeOptionValue, setMakeOptionValue] = useState(null);
  const [record, setRecord] = useState({
    quantity: "",
    partNumber: "",
    make: null
  });
  const [tempRecord, setTempRecord] = useState({
    quantity: "1",
    partNumber: "",
    make: makeOptionValue
  });
  const getErrors = () => {
    return {
      quantity: "",
      partNumber: "",
      make: ""
    };
  };
  // step1:
  useEffect(() => {
    preLoadFormValues();
  }, [state.dealerMakes, state.make]);
  useEffect(() => {
    setRecord(tempRecord);
    markDirty(dirtyField, true);
  }, [tempRecord]);

  const onChangeField = event => {
    const { name, value } = event.target;
    const prevRecord = record;

    setTempRecord({
      ...prevRecord,
      [name]: value
    });
    setDirtyField(name);
  };

  const onBlurInputField = event => {
    const { name } = event.target;
    if (isEmpty(tempRecord[name])) {
      if (name === "quantity") {
        setErrors(errorList => ({
          ...errorList,
          quantity: `Quantity is Required.`
        }));
      } else {
        setErrors(errorList => ({
          ...errorList,
          partNumber: `Part number is Required.`
        }));
      }
    }
  };
  const handleMakeChange = event => {
    const { name, value } = event.target;
    if (isDifferentValue(record[name], value)) {
      const prevRecord = record;

      setTempRecord({
        ...prevRecord,
        [name]: value
      });
      setDirtyField(name);
    }
  };
  // @note: This fun() will construct dropdown options for selected vehicle make only
  const buildMakeOptions = () => {
    const returnMakes = [];
    const { make, dealerMakes } = state;
    if (!isEmpty(dealerMakes) && make) {
      const matches = dealerMakes.filter(m => {
        return m.make.toLowerCase() === make.toLowerCase();
      });
      matches.length !== 0 &&
        matches.forEach(makeObj => {
          returnMakes.push({
            value: `${makeObj.make.toString()}-${makeObj.code}`,
            label: `${makeObj.description} - ${makeObj.code}`
          });
        });
      returnMakes.push({
        value: "OTHER-OT",
        label: "Other - OT"
      });
    }
    return returnMakes;
  };
  // merge form data with default part record
  const getPayload = () => {
    const cloneObj = Object.assign({}, mockPart);
    const tokens = record.make.split("-");
    if (!isEmpty(cloneObj)) {
      cloneObj.dtDmsPartCode = tokens[1];
      cloneObj.quantity = record.quantity;
      cloneObj.adjustedQuantity = record.quantity;
      cloneObj.oemPartNumber = record.partNumber;
      cloneObj.recordType = "INDIVIDUAL";
    }
    return cloneObj;
  };
  const preLoadFormValues = () => {
    const { dealerMakes, make } = state;
    if (!isEmpty(dealerMakes) && make) {
      const matches = dealerMakes.filter(m => {
        return m.make.toLowerCase() === state.make.toLowerCase();
      });
      let optionValue = "OTHER-OT";
      if (matches.length !== 0) {
        const makeObj = matches[0];
        optionValue = `${makeObj.make.toString()}-${makeObj.code}`;
      }
      setMakeOptionValue(optionValue);
      setTempRecord({
        quantity: "1",
        partNumber: "",
        make: optionValue
      });
    }
  };
  const resetForm = () => {
    setDirtyField("");
    setTempRecord({
      quantity: "1",
      partNumber: "",
      make: makeOptionValue
    });
    setValid(false);
    setErrors(getErrors());
    setMsg("");
    clearText();
    setLoadErrors(getErrors());
  };

  const clearText = () => {
    if (partNumRef) partNumRef.current.clear();
  };
  const handleSubmit = e => {
    e.preventDefault();
    let hasError = false;
    if (Object.keys(errors).length) {
      hasError = hasErrorStrings(errors);
      setLoadErrors(errors);
    }
    // cancel save if we have errors on fields
    if (hasError) {
      console.warn("Please correct invalid fields to proceed.");
      return;
    } else {
      // @todo: call parent handler to callback rest api to add part in service grid
      props.addIndividualPart(getPayload());
      setMsg("Part added");
      clearText();
      setTimeout(() => setMsg(""), 3000);
      resetForm();
    }
  };
  /* This function checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  const hasErrorStrings = errors => {
    const errorArray = Object.values(errors);
    const iterator = errorArray.values();
    let errCount = 0;
    for (const value of iterator) {
      // when errors has error msg
      if (!isEmpty(value) && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  };
  // validate and set dirty state
  // call this for each field change event
  const markDirty = (fieldName, isvalid) => {
    if (isvalid) {
      const valid = validate(fieldName);
      setValid(valid);
    }
  };
  const isValidForm = () => {
    const { make, quantity, partNumber } = tempRecord;
    if (!make) {
      return false;
    }
    if (!quantity || (parseInt(quantity, 10) || 0) > 1000) {
      return false;
    }
    if (!partNumber || formvalidator.validatePartNumber(partNumber)) {
      return false;
    }
    return true;
  };

  // field validator called upon onchange() of each field
  const validate = fieldName => {
    const { make, quantity, partNumber } = tempRecord;
    if (fieldName === "quantity") {
      if (!quantity) {
        errors["quantity"] = !quantity ? "Quantity is Required." : "";
      } else {
        if ((parseInt(quantity, 10) || 0) > 1000) {
          errors["quantity"] = "The Maximum value for this field is 1000";
        } else if ((parseInt(quantity, 10) || 0) <= 0) {
          errors["quantity"] = "The Min value for this field is 1";
        } else {
          errors["quantity"] = "";
        }
      }
    }
    if (fieldName === "partNumber") {
      errors["partNumber"] = !partNumber ? "PartNumber is Required." : "";
      if (isEmpty(errors["partNumber"])) {
        errors["partNumber"] = formvalidator.validatePartNumber(
          "Part number",
          partNumber
        );
      }
    }
    if (fieldName === "make") {
      errors["make"] = !make ? "Make is Required." : "";
    }
    setErrors(errors);
    const isValid = isValidForm();
    return isValid;
  };

  const statusHtml = msg ? (
    <StatusBox
      htmlId="statusBox"
      type="success"
      autoClose={true}
      linkHtml={null}
      message={msg}
      errorInTooltip={false}
    />
  ) : (
    ""
  );

  const addPartsError = errorCode => {
    const errorMsg =
      errorCode === 404
        ? "Verify the part number is correct and added to the inventory."
        : "There may be a system issue. Try again.";
    return (
      <div className="add-parts-error-container">
        <div className="add-parts-error">
          <div className="add-parts-error-icon-container">
            <span className="add-parts-error-icon"> ! </span>
          </div>
          <p className="add-parts-error-message">
            No part was found. {errorMsg}
          </p>
        </div>
      </div>
    );
  };

  return (
    <form id="individualPartForm" autoComplete="off" onSubmit={handleSubmit}>
      <div className="individual-parts-container">
        <div className="parts-row">
          <div className="part-fixed-col">
            <p className="sq-label">
              Quantity
              <span className="sq-red-label"> * </span>
            </p>
            <NumericInput
              htmlId="quantity"
              label=""
              displayLabel={false}
              placeholder=""
              displayPlaceholder={true}
              name="quantity"
              onChange={onChangeField}
              onBlur={onBlurInputField}
              minLength={1}
              minValue={1}
              // maxValue={1000}
              maxLength={4}
              allowDecimal={false}
              value={tempRecord.quantity}
              autoComplete="off"
              error={loadErrors.quantity}
            />
          </div>

          <div className="part-fixed-col">
            <p className="sq-label">
              Part number
              <span className="sq-red-label"> * </span>
            </p>
            <TextInput
              htmlId="partNumber"
              label=""
              name="partNumber"
              autoFocus
              displayLabel={false}
              placeholder=""
              minLength={1}
              maxLength={50}
              onChange={onChangeField}
              onBlur={onBlurInputField}
              value={tempRecord.partNumber}
              autoComplete="off"
              ref={partNumRef}
              error={loadErrors.partNumber}
            />
          </div>
          <div className="make-field">
            <p className="sq-label">
              Make
              <span className="sq-red-label"> * </span>
            </p>
            <SelectInput
              htmlId="make"
              name="make"
              label="Make"
              maxHeight={150}
              options={[...buildMakeOptions()]}
              value={tempRecord.make}
              displayDeselectOption={false}
              placeholder="Select make"
              onChange={handleMakeChange}
              className="select-field"
              error={loadErrors.make}
              displayLabel={false}
            />
          </div>
        </div>
        <div className="lookup-modal-footer">
          {props.newPartError > 0 ? addPartsError(props.newPartError) : null}
          {statusHtml}
          <Button
            htmlId="addIndividualPartBtn"
            buttonStyle="secondary"
            type="submit"
          >
            Add service parts
          </Button>
        </div>
        {/* <input
          autoComplete="on"
          style={{ display: "none" }}
          id="fake-hidden-input-to-stop-auto-complete"
        /> */}
      </div>
    </form>
  );
};
export default AddPartComponent;
